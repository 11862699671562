@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$poppin: 'Poppins',
    sans-serif;
$black: #000;
$white: #fff;
$white-50: #ffffff80;
$gray: #888888;
$main_text_color: #272727;
$blue-2: #343434;
$mainColor: #a37d39;
$secondaryColor: #f3745f;
$thirdColor: #E5D3B3;
$bgColor: #fff;
$buttonbgColor: #272727;