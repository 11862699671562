body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.grid-layout-container {
  -webkit-box-shadow: 0px 0px 16px 0px #00000057;
  box-shadow: 0px 0px 16px 0px #00000021;
  display: grid;
  place-items: center;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.037);
  border-radius: 10px;
  width: 250px;
  margin: 15px 0px !important;
  position: relative;
}

.grid-layout-container button {
  outline: none;
  border: none;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: black;
  color: white;
}
.form .css-1s2u09g-control {
  height: max-content !important;
}

@media print {
  .displayNone {
    display: none;
    width: 0px !important;
  }

  .displayNoneMain {
    /* display: none; */
    width: 100vw !important;
  }

  header {
    width: 0px !important;
  }

  footer {
    width: 0px !important;
  }

  #sidebar {
    width: 0px !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.pdf {
  background-color: #fff;
  overflow: hidden;
  padding: 4pc 4pc 0;
}

.top-logo {
  padding-bottom: 2pc;
}

.mid-intro {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  padding: 3pc 0;
  margin-bottom: 3pc;
}

.mid-intro p {
  margin-bottom: 0;
  line-height: normal;
}

.mid-intro p,
.mid-intro h5 {
  text-align: end;
}

.fw-semibold {
  font-weight: 600;
}

.order-info .title {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  padding: 1pc 0;
  text-align: center;
}

.address {
  margin: 3pc 0;
}

.address p {
  margin-bottom: 0;
  line-height: normal;
}

.address .col-12:not(:first-child) {
  border-left: 1px solid #dee2e6;
  padding-top: 1pc;
  padding-bottom: 1pc;
}

.all-taxes li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  color: #000;
  font-weight: 600;
}

.all-taxes li:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.bottom {
  border-top: 1px solid #dee2e6;
  padding: 1pc 0;
  font-weight: 600;
  font-size: 17px;
}

@media only screen and (min-width: 1200px) {
  .main-swiper-content h1 {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1200px) {
  .main-swiper-slide {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .main-banner .main-swiper-slide .main-swiper-content {
    padding: 3pc 3pc;
  }
}

@media only screen and (max-width: 768px) {
  .main-banner .main-swiper-slide .main-swiper-content p {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 567px) {
  .main-banner .main-swiper-slide .main-swiper-content {
    padding: 3pc 1pc !important;
  }
}

::-webkit-scrollbar {
  width: 0;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.btn:focus {
  box-shadow: none;
}

.btn-custom {
  font-weight: 500;
  --bs-btn-padding-x: 1pc;
}

.btn-hover {
  position: relative;
  overflow: hidden;
}

.btn-hover:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.5019607843);
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  content: "";
  border-radius: 3px;
  transition: all 0.5s;
}

.btn-hover:hover:before {
  transition: all 0.5s;
  left: 0;
  transform: translateX(-100%);
}

.btn-brown {
  background-color: #b79f82;
  color: #fff;
}

.btn-brown:hover {
  background-color: #b79f82 !important;
  color: #fff !important;
}

.poppin {
  font-family: "Poppins", sans-serif !important;
}

.orange {
  color: #f18024 !important;
}

.orange-bg {
  background-color: #f18024 !important;
}

.gray-bg {
  background-color: #f3f4f8 !important;
}

.fs-15 {
  font-size: 15px;
}

.list-circle li {
  list-style-type: circle;
  list-style-position: inside;
}

.flex-1 {
  flex: 1;
}

.title-section .heading {
  line-height: 50px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 35px;
}

.title-section .heading + p {
  font-size: 20px;
  line-height: 40px;
}

.title-section h6 {
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  margin: 0;
}

p {
  line-height: 1.8em;
}

p:last-child {
  margin-bottom: 0;
}

.img-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.img-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.img-position-top {
  -o-object-position: top;
  object-position: top;
}

.img-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
}

a:hover {
  transition: all 0.5s;
  color: #f18024;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.main-logo {
  height: 80px;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.line {
  width: 70px;
  background: #fff;
  height: 1px;
}
